import { useQuery, QueryHookOptions } from '@apollo/client'
import { Membership, MembershipVariables } from 'src/graphql-types/saas/Membership'
import { GET_MEMBERSHIP } from './saas-membership.queries'
import { meshGatewayClient } from 'src/apollo/client'

interface UseSaaSMembershipProps {
  options?: Omit<QueryHookOptions<Membership, MembershipVariables>, 'variables' | 'client'>
  id: string | undefined
}

export function useSaaSMembership(props: UseSaaSMembershipProps) {
  const { id, options = {} } = props || {}
  const query = useQuery(GET_MEMBERSHIP, {
    client: meshGatewayClient,
    variables: { id },
    ...options
  })
  return {
    ...query,
    membership: query?.data?.membership,
    loadingMembership: query.loading
  }
}
