import React from 'react'
import Breadcrumbs from 'src/components/breadcrumbs/breadcrumbs'
import { useTranslation } from 'react-i18next'
import ROUTES from 'src/utils/routes'
import { PageMaxWidth } from 'src/components/util-components/util-components'
import Panel from 'src/components/panel/panel'
import { Grid } from '@material-ui/core'
import { H4, H2, H3 } from 'src/components/typography/typography'
import EmptyState from 'src/components/empty-state/empty-state'
import AdminTable from 'src/components/admin-table/admin-table'
import { useParams } from '@reach/router'
import * as styles from './saas-membership.module.less'
import { SpacingProps, useSpacing } from 'src/hooks/spacing'
import cx from 'classnames'
import { capitalize } from 'src/utils/helper/rankings'
import Spinner from 'src/components/spinner/spinner'
import { useSaaSMembership } from './saas-membership.api'

interface ItemProps {
  dt: string
  dd?: string
  leftMargin?: boolean
}

function Item({ dt, dd, leftMargin = true }: ItemProps) {
  return (
    <dl
      className={cx(styles.itemContainer, {
        [styles.itemContainerLeftMargin]: leftMargin
      })}
    >
      <dt className={styles.itemDt}>{dt}</dt>
      {dd && <dd className={styles.itemDd}>{dd}</dd>}
    </dl>
  )
}

export function SaaSMembership({ membershipId }: { membershipId: string | undefined }) {
  const { t } = useTranslation()
  const { membership, loadingMembership } = useSaaSMembership({ id: membershipId })

  if (loadingMembership) {
    return <Spinner />
  }

  return (
    <>
      <Breadcrumbs
        paths={[
          { name: t('membership list'), to: ROUTES.MEMBERSHIPS },
          { name: membership?.name || '', active: true }
        ]}
      />
      <PageMaxWidth>
        <Panel>
          <Grid container>
            <H2>{membership?.name || ''}</H2>
            <div className={styles.divider} />
          </Grid>
          <Grid container>
            <Item dt={t('code')} dd={membership?.code} leftMargin={false} />
            <Item dt={t('type')} dd={capitalize(membership?.type?.toLowerCase())} />
            {/* <Item dt={t('start date')} dd={''} /> */}
            {/* <Item dt={t('end date')} dd={''} /> */}
            {/* <Item dt={t('memberships sold')} dd={''} /> */}
            {/* <Item dt={t('revenue')} dd={''} /> */}
            {/* <Item dt={t('status')} dd={''} /> */}
          </Grid>
        </Panel>

        {/* <Panel> */}
        {/*   <> */}
        {/*     {false ? ( */}
        {/*       <EmptyState title="There are no members" icon="lg-empty-state" /> */}
        {/*     ) : ( */}
        {/*       <Grid container> */}
        {/*         <H3>{t('organizations')}</H3> */}
        {/*         <div className={styles.divider} /> */}
        {/*       </Grid> */}
        {/*     )} */}
        {/*   </> */}
        {/* </Panel> */}
      </PageMaxWidth>
    </>
  )
}
