import { RouteComponentProps, Router } from '@reach/router'
import React from 'react'
import Layout from 'src/components/layout/layout'
import Membership from 'src/components/membership/membership'
import SEO from 'src/components/seo'
import { usePrivateRoute } from 'src/utils/auth'
import { getClientConfig } from 'src/config/config'
import { SaaSMembership } from 'src/features/saas/saas-membership/saas-membership'

const MembershipPage = () => {
  usePrivateRoute()
  return (
    <Layout>
      <SEO title="Membership" />
      <Router basepath="/memberships">
        <MembershipRoute path="/:membershipId" />
      </Router>
    </Layout>
  )
}

interface MembershipRouteProps extends RouteComponentProps {
  membershipId?: string
}

const MembershipRoute: React.FC<MembershipRouteProps> = ({ membershipId }) => {
  const { isSaaS } = getClientConfig()
  return (
    <>
      {isSaaS ? (
        <SaaSMembership membershipId={membershipId} />
      ) : (
        <Membership membershipId={membershipId} />
      )}
    </>
  )
}

export default MembershipPage
