import graphql from 'graphql-tag'

export const GET_MEMBERSHIP = graphql`
  query Membership($id: ID!) {
    membership(id: $id) {
      id
      name
      type
      code
      description
      benefits
      confirmation
      region
      terms {
        id
        name
        code
      }
      tax
    }
  }
`
